import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Api, { IUserStatistics } from '../../services/Api';
import Environment from '../../services/Environment';

const StatisticsContainer = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr 1fr;
  grid-template-rows: 12rem;
  height: 12rem;
  color: ${(props) => props.theme.colors.white};
  font-family: 'teko';
  font-weight: 100;
  margin-bottom: 2rem;
`;
const OurStats = styled.div`
  display: inline-block;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

  width: 5rem;

  font-size: 2.5rem;
  text-transform: uppercase;
  
  background: #043276;

  &::before {
    content: 'Our Stats';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    letter-spacing: 1px;
    transform: translate(-50%, -50%) rotate(-90deg);
  }
`;
const StatisticsSubContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
  padding: 1.2rem 2rem;

  &:nth-child(2) {
  background-color: #023C91;
}
  &:nth-child(3) {
  background-color: #0D50B3;
  }
`;
const StatisticsSubContainerHeader = styled.div`
  display: block;
  font-family: 'teko';
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1.5rem;
  letter-spacing: 0.25px;

`;
const StatisticsSectionContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  flex-direction: row;
`;
const StatisticsSection = styled.div`
  display: block;
  flex-grow: 1;
`;
const StatisticsSectionValue = styled.div`
  display: block;
  font-family: 'teko';
  font-size: 6.25rem;
  padding-top: 1.25rem;
  padding-bottom: 0.5rem;
  line-height: 4rem;
  text-align: center;
  font-weight: 400;
`;
const StatisticsSectionDescription = styled.div`
  display: block;
  font-family: 'Overpass';
  text-align: center;
  font-size: 1.125rem;
  font-weight: 400;
  color: #AED2E1;

`;
const StatisticsLoader = styled.div`
  position: relative;
  display: block;
  height: 12rem;
  display: block;
  background-color: #0D50B3;
  margin-bottom: 2rem;
`;

const StatisticsSectionSeparator = styled.div`
  position: relative;
  display: inline-block;
  width: 2rem;

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    left: 50%;
    background: #BABFC7;
    width: 1px;
  }
`;

export const StatisticsOverview: React.FC = () => {
  const [userStatistics, setUserStatistics] = useState<IUserStatistics | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = async () => {
    try {
      const result = await Api.getUserStatistics();
      
      setUserStatistics(result);
    } catch (e) {
      if (Environment.getCurrentEnvironment().isForDebugging) {
        setUserStatistics({
          upcomingSessionCount: 18,
          completedSessionCount: 2,
          completedChapter2Percentage: 97,
          completedChapter5Percentage: 3
        });
      }

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // can't seemlessly async within this function, cleaner to move the method outside for better handling
    handleLoad();
  }, []);

  if (isLoading) return <StatisticsLoader></StatisticsLoader>;
  if (!userStatistics) return <></>;

  const upcomingSessionCount = userStatistics.upcomingSessionCount || 0;
  const completedSessionCount = userStatistics.completedSessionCount || 0;
  const completedChapter2Percentage = userStatistics.completedChapter2Percentage || 0;
  const completedChapter5Percentage = userStatistics.completedChapter5Percentage || 0;
  

  return <StatisticsContainer>
    <OurStats></OurStats>
    <StatisticsSubContainer>
      <StatisticsSubContainerHeader>Season's active sessions</StatisticsSubContainerHeader>
      <StatisticsSectionContainer>
        <StatisticsSection>
          <StatisticsSectionValue>{`${upcomingSessionCount}`}</StatisticsSectionValue>
          <StatisticsSectionDescription>Upcoming Sessions</StatisticsSectionDescription>
        </StatisticsSection>
        <StatisticsSectionSeparator />
        <StatisticsSection>
          <StatisticsSectionValue>{`${completedSessionCount}`}</StatisticsSectionValue>
          <StatisticsSectionDescription>Completed Sessions</StatisticsSectionDescription>
        </StatisticsSection>
      </StatisticsSectionContainer>
    </StatisticsSubContainer>
    <StatisticsSubContainer>
      <StatisticsSubContainerHeader>Season's Challenge Completion Rates</StatisticsSubContainerHeader>
      <StatisticsSectionContainer>
        <StatisticsSection>
          <StatisticsSectionValue>{`${completedChapter2Percentage}`}%</StatisticsSectionValue>
          <StatisticsSectionDescription>Completion Chapt. 2</StatisticsSectionDescription>
        </StatisticsSection>
        <StatisticsSectionSeparator />
        <StatisticsSection>
          <StatisticsSectionValue>{`${completedChapter5Percentage}`}%</StatisticsSectionValue>
          <StatisticsSectionDescription>Completion Chapt. 5</StatisticsSectionDescription>
        </StatisticsSection>
      </StatisticsSectionContainer>
    </StatisticsSubContainer>
  </StatisticsContainer>;
}
